@import "../../index.less";
@mobile: 480px;
.select {
	border-radius: 8px;
}
.btn_1 {
	font-size: 18px;
	color: #003778;
	width: 228px;
	height: 54px;
	border-radius: 4px;
	border: 1px solid rgba(0, 55, 120, 1);
}

.btn_2 {
	font-size: 18px;
	color: #f0f0f0;
	width: 228px;
	height: 54px;
	border-radius: 4px;
	background-color: rgba(0, 55, 120, 1);
}
.loginContainer {
	height: 100%;
	background-image: url("../../assets/loginBG.png");
	background-size: cover; /* Ensures the image covers the entire container */
	background-position: center; /* Centers the image */
	background-repeat: no-repeat; /* Prevents the image from repeating */
}
.LoginBox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	height: 100%;
	padding: 52px 60px;
	@media screen and (max-width: @mobile) {
		padding: 1.25rem;
	}

	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;

		nav {
			a {
				font-size: 20px;
				margin-left: 73px;
				color: #333;

				@media screen and (max-width: @mobile) {
					margin-left: 1rem;
					font-size: 16px; // Adjust font size for better readability
				}
			}
		}

		.LogoBox {
			margin-top: 7rem;

			img {
				width: 200px;
				@media screen and (max-width: @mobile) {
					width: 160px;
				}
			}
			@media screen and (max-width: @mobile) {
				margin-top: 8rem;
			}
		}

		// @media screen and (max-width: @mobile) {
		// 	flex-direction: column; // Stack items vertically
		// 	align-items: flex-start; // Align items to the start
		// 	gap: 10px; // Reduce gap for smaller screens

		// 	nav {
		// 		a {
		// 			margin-left: 0; // Reset margin for mobile
		// 		}
		// 	}
		// }
	}
	.nav {
		display: flex;
		gap: 0px;
		// margin-left: 10px;
	}
	.LoginFormBox {
		// margin: auto;
		// margin-top: 2rem;
		width: 600px;
		height: 50%;
		@media screen and (max-width: @mobile) {
			width: 95%;
		}

		.LoginTitleBox {
			> h2 {
				font-size: 3.5rem;
				margin: 30px 0px;
				text-align: center;
				font: normal normal 36px/46px Inter;
				letter-spacing: 0px;
				color: #2e373c;
				opacity: 1;
				margin-top: 2rem;
				// margin-top: 7rem;
				@media screen and (max-width: @mobile) {
					font-size: 3rem;
				}
			}
			> h3 {
				text-align: center;
			}

			.Login_Bottom_Line {
				width: 54px;
				border-bottom: 4px solid #ddd;
				border-image: linear-gradient(rgba(17, 94, 186), rgba(24, 142, 186)) 26
					26;
			}
		}
	}

	.ant-form {
		width: 100%;
		margin-top: 30px;
	}

	.ant-form-item-label > label::after,
	.ant-form-item-label
		> label.ant-form-item-required:not(
			.ant-form-item-required-mark-optional
		)::before {
		width: 0;
		content: "";
	}

	.ant-form-horizontal .ant-form-item-label {
		text-align: left;
		width: 25%;
		margin: 0 auto 1em -5px;
	}

	.ant-form-item-required:nth-child(1) {
		font-size: 24px;
		padding: 0;
		color: #333;
		height: 20px;
	}

	.ant-input-affix-wrapper,
	.ant-input {
		height: 65px !important;
		background: none;
		border: 1px solid rgb(212, 226, 240);
		// line-height: 1.5715;
		border-radius: 6px !important;
	}

	.ant-input-affix-wrapper > input.ant-input {
		font-size: 16px;
		padding-bottom: 3px;
		border: none;
		outline: none;
	}

	.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
		border: 1px solid rgb(212, 226, 240);
	}

	#login_password {
		height: 44px !important;
	}

	.ant-input,
	.ant-input-suffix {
		height: 65px;
	}

	.Login_Btn_Label {
		.ant-form-item-control-input-content {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			// margin-top: 3rem;
			// @media screen and (max-width: @mobile) {
			// 	display: flex;
			// 	flex-direction: column;
			// }

			.Sign_Btn_Box {
				border: none;
				visibility: initial;
				display: block;

				width: 180px;
				height: 56px;
				background: url(../../assets/images/login/login_in_btn.png) no-repeat
					center center;
			}

			.Login_Btn_Box {
				background: #f47b23;
				border-radius: 8px;
				box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
					0px 2px 2px 0px rgba(0, 0, 0, 0.14),
					0px 3px 1px -2px rgba(0, 0, 0, 0.2);
				display: flex;
				width: 10rem;
				height: 3.3995rem;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				border: none;
				font-size: 20px;
				letter-spacing: 0;
				font-weight: 500;
				font-family: inter;
				padding: 5px;
			}
			.Login_Btn_Box:hover {
				background: #d3691c;
			}
			.Sign_Btn_Box {
				background: url(../../assets/images/login/sign_up_btn.png) no-repeat
					center center;
			}
		}
	}
}

.LoginBg {
	background-image: url(../../assets/images/login/login_bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	zoom: 125%;
}

/* Base styles (default) */
.forget {
	cursor: pointer;
	font-size: 20px;
	font-weight: 500;
	color: white;
}

.forget:hover {
	color: #f47b23;
	text-decoration: underline;
	transition: 0.3s;
}

.inputLogin {
	background: white !important;
	border: solid 3px #cecdd6 !important;
}

.headerimg {
	margin-top: 4rem;
	margin-left: 4rem;
}

/* Media query for screens scaled at 150% */
@media (resolution: 1.5dppx) {
	.LoginBox .LoginFormBox {
		width: 425px;

		height: unset !important;
	}
	.LoginBox .ant-form {
		margin-top: 0px !important;
	}
	.LoginBox {
		padding: 10px !important;
	}
	.forget {
		font-size: 13.33px; /* Adjusted font size for scaling */
	}

	.inputLogin {
		width: 100% !important;
		border: solid 2px #cecdd6 !important; /* Adjusted border for scaling */
	}

	.headerimg {
		width: 30rem;
		margin-top: 20px; /* Adjusted margins */
		margin-left: 10px;
	}
}

/* For mobile responsiveness */
@media screen and (max-width: 768px) {
	.headerimg {
		width: 90%;
	}
}
// @media (resolution: 1.5dppx) {
// 	.ant-layout-sider-children {
// 		margin-top: 30px !important;
// 	}
// }
.find_Password {
	.form_Email {
		padding: 40px 0px 40px 0px;
	}
	.ant-modal-title {
		border-left: 7px solid rgb(0, 55, 120, 1);
		padding-left: 17px;
		font-size: 20px;
	}

	.ant-input {
		height: 40px;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-variant: tabular-nums;
		list-style: none;
		font-feature-settings: "tnum", "tnum";
		position: relative;
		display: inline-block;
		width: 100%;
		min-width: 0;
		padding: 4px 11px;
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
		line-height: 1.5715;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		transition: all 0.3s;
	}

	.ant-modal-body {
		padding: 20px 0px;
	}

	.ant-form {
		border-bottom: 1px solid #f0f0f0;
	}

	.ant-form-item-label > label {
		padding-top: 3px;
		position: relative;
		display: inline-flex;
		align-items: center;
		color: rgba(102, 102, 102, 100);
		font-size: 18px;
	}

	.top_tip {
		color: rgba(153, 153, 153, 100);
		font-size: 20px;
		text-align: center;
	}
}

//用户注册弹窗样式
.new_User {
	.ant-modal-title {
		border-left: 7px solid rgb(0, 55, 120, 1);
		padding-left: 17px;
		font-size: 20px;
	}

	.ant-input-affix-wrapper {
		height: 48px;
		position: relative;
		display: inline-block;
		width: 90%;
		min-width: 0;
		padding: 4px 11px;
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
		line-height: 1.5715;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		transition: all 0.3s;
		display: inline-flex;
	}

	.ant-modal-body {
		padding: 24px 0px;
	}

	.ant-form {
		border-bottom: 1px solid #f0f0f0;
	}

	.newBox {
		padding: 20px 0px 20px 35px;
	}

	.span_title {
		font-size: 20px;
		color: #333333;
	}

	p {
		margin-bottom: 0.5em;
		font-size: 18px;
		color: #333333;
	}
}
.whiteTxt {
	font-weight: 500;
	font-size: 1rem;
	color: white;
}
.footer {
	display: flex;
	justify-content: space-evenly;
	// margin: auto;
	align-items: center;
	gap: 20px;
	// @media screen and (max-width: @mobile) {
	// 	width: 65%;
	// }
	.linkGoLogin {
		color: #02aee8 !important;
		text-decoration: underline;
	}
	.linkItem {
		color: white;
		font-weight: 500;
		font-size: 1rem;
	}

	.linkItem:hover,
	.linkGoLogin:hover {
		color: #f47b23 !important;
		transition: 0.3s;
		text-decoration: underline;
	}
}

.backToLoginBtn {
	display: flex;
	font-size: 1.4rem;
	width: 100%;
	height: 4rem;
	margin-top: 4rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 1rem;
	background: #000;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.titleFAQ {
	background-color: #356da8;
	height: 11rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3rem;

	h1 {
		color: white;
		font-size: 2.5rem;
		font-style: normal;
		font-weight: 700;
	}
}
.Login_Btn_Box_Reset {
	width: 100%;
	height: 45px;
	padding: 5px;
	font: 18px/30px Inter;
	font-weight: 500;
	letter-spacing: 0;
	text-align: center;
	// border-radius: 0.25rem;
	background: #f47b23 !important;
	border: none;
	border-radius: 8px;
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12),
		0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.Login_Btn_Box_Reset:hover {
	background: #d3691c !important;
}
.Login_Btn_Box_Reset_small {
	margin-bottom: 2rem;
	width: 100%;
	height: 40px;
	padding: 5px;
	color: white;
	font: 16px/30px Inter;
	font-weight: 500;
	letter-spacing: 0;
	text-align: center;
	background: #02aee8 !important;
	border: none !important;
	border-radius: 8px;
}
.Login_Btn_Box_Reset_small:hover {
	color: white !important;
	background: #0099cc !important;
	border: none !important;
}
.Login_Btn_Box_Reset_m {
	width: 100%;
	height: 40px;
	padding: 5px;
	color: white;
	font: 16px/30px Inter;
	font-weight: 500;
	letter-spacing: 0;
	text-align: center;
	background: #02aee8;
	border: none !important;
	border-radius: 8px;
	margin: 3rem 0px;
}
.Login_Btn_Box_Reset_m:hover {
	color: white;
	background: #0099cc;
	border: none !important;
}
.faqContainer {
	width: 70%;
	margin: auto;
	margin-top: 5rem;
	margin-bottom: 5rem;

	h3 {
		font-size: 1.5rem;
		color: #2b66a4;
		font-style: normal;
		font-weight: 700;
		line-height: 2.1875rem; /* 145.833% */
	}

	.faqAnswer {
		font-size: 1.5rem;
		margin-top: 2rem;
	}

	.orangeLine {
		width: 3.5rem;
		height: 0.125rem;
		border-radius: 0.5rem;
		background: #f4791f;
	}
}

.searchContainer {
	border-radius: 0.75rem;
	background: #fff;
	box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.07);
	display: flex;
	width: 46.5rem;
	height: 4rem;
	padding: 0.5rem 1.25rem 0.5rem 1rem;
	align-items: center;
	gap: 1rem;
}

.topics {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: space-around;
}

.topicsContainer {
	width: 31%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 19.625rem;
	flex-shrink: 0;
	border-radius: 1.125rem;
	background: var(--Neutral-100, #fff);
	box-shadow: 0px 4px 40px 0px rgba(119, 119, 119, 0.06);
	margin-top: 2.5rem;
	padding: 1rem;

	.topicTitle {
		color: #170f49;
		font-size: 1.8rem;
		font-weight: 700;
	}

	.topicContent {
		color: var(--Neutral-600, #6f6c90);
		text-align: center;
		font-size: 1rem;
		font-weight: 400;
		line-height: 2rem;
	}
}

.searchBtn {
	height: 65px;
	border-radius: 0.625rem;
	background: #f4791f;
	display: flex;
	padding: 0.625rem 1.5rem;
	align-items: center;
	gap: 0.5rem;
	color: var(--White, #fff);
	text-align: center;
	font-feature-settings: "clig" off, "liga" off;
	font-size: 1.3rem;
}

.custom-range-picker {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.custom-range-picker .ant-picker-input {
	flex: 1;
	margin-right: 8px; /* Adjust spacing between the two inputs */
}

.custom-range-picker .ant-picker-input:last-child {
	margin-right: 0;
}

@primary-color: #003778;@border-radius-base: 6px;@layout-header-background: #ffffff;@layout-sider-background: #003778;@menu-bg: #003778;@menu-inline-toplevel-item-height: 94px;@menu-item-height: 53px;@menu-item-color: #ffffff;@menu-highlight-color: #ffffff;@menu-inline-submenu-bg: #003778;@menu-item-active-bg: rgba(128, 160, 231, 0.3);@menu-popup-bg: #003778;@menu-item-active-border-width: 0px;